@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,opsz,wght@0,8..60,600;0,8..60,700;1,8..60,600&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@700,401,400&display=swap");

@layer base {
  .alert-radio-label {
    /* @apply text-secondary; */
  }

  body {
    @apply text-sm;
  }
}

:root {
  --ion-font-family: "satoshi", "sans-serif";
}

@layer components {
  ion-button {
    --border-radius: 1.5rem;
    font-weight: 600;
  }
  ion-tab-button {
    --color-selected: theme("colors.white");
  }
  ion-modal {
    --min-height: 70%;
  }
  ion-searchbar.marketplace {
    --background: theme("colors.white");
    --placeholder-color: theme("colors.grey");
    --placeholder-opacity: 1;
    --color: theme("colors.grey");
    --icon-color: theme("colors.deep-blue");
    --border-radius: 1.5rem;
    padding-left: 0;
  }
  ion-item {
    --background: theme("colors.white");
  }
  ion-list {
    --background: theme("colors.white");
  }
  .text-headline {
    @apply font-headline font-bold leading-5;
  }
}

@layer utilities {
  .section-heading {
    @apply text-headline text-lg mb-2 mt-4 text-black;
  }

  .section-card {
    @apply bg-white rounded-2xl p-4 mb-4 shadow-sm mx-0;
  }
}

/* .ios .alert-wrapper {
  background-color: #1c414d;
} */
